/**
 * @component whisbi-video
 * @author Phil &amp; Maik
 *
 * Javascript
 */

(function () {
    'use strict';

    /**
     * Configuration object for OpeningHours, ClosedDays, video background src image, animationTime and classes used in the red / grey badge
     */
    var configuration = {
        CallCenterOpeningHours: {
            '1': ['08:00', '20:00'],
            '2': ['08:00', '20:00'],
            '3': ['08:00', '20:00'],
            '4': ['08:00', '20:00'],
            '5': ['08:00', '20:00'],
            '6': ['09:00', '17:00'],
            '7': ['08:00', '14:00'], // christmas eve and silvester
        },
        RetailStoreOpeningHours: {
            '1': ['08:00', '20:00'],
            '2': ['08:00', '20:00'],
            '3': ['08:00', '20:00'],
            '4': ['08:00', '20:00'],
            '5': ['08:00', '20:00'],
            '6': ['09:00', '18:30'],
            '7': ['08:00', '14:00'], // christmas eve and silvester
        },
        specialDays: [
            '24-12-2019',
            '31-12-2019',
        ],
        FilialeClosedDays: [
            '27-05-2019',
            '30-05-2019',
            '10-06-2019',
            '03-10-2019',
            '25-12-2019',
            '26-12-2019',
            '01-01-2020'
        ],
        imgPath: '//s3-eu-west-1.amazonaws.com/static.whisbi.com/live-webcams/vdfdecam.jpg?',
        animationTime: 1000,
        visibleImageClass: '.visible-img',
        RedBadgeClass: '.red-bar',
        GreyBadgeClass: '.icon-bar',
        RedBadgeTextRetailStore: '<span><b>Wir sind für Dich da</b> – Montag bis Freitag von 8 bis 20 Uhr und Samstag von 9 bis 18:30 Uhr. Außer an Feiertagen.</span>',
        RedBadgeTextCallCenter: '<span><b>Wir sind für Dich da</b> – Montag bis Freitag von 8 bis 20 Uhr und Samstag von 9 bis 17 Uhr. Außer an Feiertagen.</span>'
    };

    /**
     * creates a formatted string of the current day (today) in the form of i.e. 30.04.2019
     * @returns String
     */
    var formatDate = function () {
        var date = new Date(),
            d = date.getDate(),
            m = date.getMonth() + 1,
            y = date.getFullYear();
        return '' + (9 >= d ? '0' + d : d) + '-' + (9 >= m ? '0' + m : m) + '-' + y;
    };

    /**
     * Function to set a new date object with updated hours
     * @param config Whisbi configuration
     * @param weekdayPointer Pointer for needed week day
     * @param arrayPointer Pointer for taking open or closing time
     * @returns {object}
     */
    var setHours = function (config, weekdayPointer, arrayPointer) {
        return new Date().setHours(
            config[weekdayPointer][arrayPointer].split(':')[0], // hours
            config[weekdayPointer][arrayPointer].split(':')[1], // minutes
            0
        );
    };

    /**
     * Checks if the filiale should be open depending on the opening hours set in the configuration.CallCenterOpeningHours object
     * @returns Boolean
     */
    var isFilialeOpen = function (whisbiMode) {
        var isWithinOpeningHours = false,
            validConfigHours = {},
            openingTime,
            closingTime,
            now = new Date(),
            formattedDate = formatDate(),
            weekday = now.getDay();

        switch (whisbiMode) {
        case 'retail-store' :
            validConfigHours = configuration.RetailStoreOpeningHours;
            break;
        case 'call-center' :
            validConfigHours = configuration.CallCenterOpeningHours;
            break;
        default:
            validConfigHours = configuration.CallCenterOpeningHours;
            break;
        };

        if (-1 !== configuration.specialDays.indexOf(formattedDate)) {
            // if current day is christmas eve or silvester those have different opening hours
            openingTime = setHours(validConfigHours, 7, 0);
            closingTime = setHours(validConfigHours, 7, 1);

            isWithinOpeningHours = now > openingTime && now < closingTime;

        } else if (0 !== weekday) {
            openingTime = setHours(validConfigHours, weekday, 0);
            closingTime = setHours(validConfigHours, weekday, 1);

            isWithinOpeningHours = now > openingTime && now < closingTime;
        }

        return isWithinOpeningHours;
    };

    /**
     * Checks if the filiale should be closed depending on the Bank holidays set in the configuration.FilialeClosedDays
     * @returns Boolean
     */
    var isFilialeClosed = function () {

        var isClosed = false,
            formattedDate = formatDate();

        for (var i = 0; i < configuration.FilialeClosedDays.length; i++) {
            isClosed = formattedDate === configuration.FilialeClosedDays[i];
        }
        return isClosed;
    };


    /**
     * Creates a random number to be used in the src of the image in order to load a new image every 1 second
     * @returns Number
     */
    var randomValueForImage = function () {
        return (Math.random() * 1000000000000) >> 0;
    };

    /**
     * Gets from the Url the parameters and returns the value of the one passed as an argument
     * @param {*} parameterName
     * @returns String
     * Example: for URL : https://eweb6.vfd2-testnet.de/hilfe/online-filiale.html?icmp=flyout&whisbimode=retail-store&branch=3kjjr98je98ds7f8dsf799efd
     * and parameterName = whisbimode will return retail-store
     */
    var getUrlParameterValue = function (parameterName) {
        var url = decodeURIComponent(window.location.href),
            searchString = '',
            parameters = [],
            keyValueArr = [],
            urlParameterValue = '';

        if (-1 !== url.indexOf('?')) {
            searchString = url.split('?')[1] || '';
        }
        parameters = searchString.split('&');

        for (var i = 0; i < parameters.length; i++) {
            keyValueArr = parameters[i].split('=');
            if (parameterName === keyValueArr[0]) {
                urlParameterValue = keyValueArr[1];
            }
        }

        return urlParameterValue;

    };

    /**
     *  Hide both red badge either call center or retail store with closing hours
     */
    var hideRedBadge = function () {
        $(configuration.GreyBadgeClass).removeClass('access');
        $(configuration.RedBadgeClass).addClass('access');
    };

    /**
     * Hide the grey promo badge with static icons and show the correct Red Badge with either retail store or call center opening hours
     */
    var hideGreyBadge = function () {

        $(configuration.GreyBadgeClass).addClass('access');
        $(configuration.RedBadgeClass).removeClass('access');

    };

    var setRedBadgeText = function () {
        // When showing the Red Badge with the opening hours we must choose the correct opening hours to display for each different experience
        var whisbiModeValue = getUrlParameterValue('whisbimode') || '';
        switch (whisbiModeValue) {
        case 'retail-store':
            $(configuration.RedBadgeClass).html(configuration.RedBadgeTextRetailStore);
            break;
        case 'call-center':
            $(configuration.RedBadgeClass).html(configuration.RedBadgeTextCallCenter);
            break;
        default:
            $(configuration.RedBadgeClass).html(configuration.RedBadgeTextCallCenter);
            break;
        }
    };

    /**
     * Get the cached image source and set it to the visible image
     */
    var updateBackground = function () {
        var t = randomValueForImage();
        $(configuration.visibleImageClass).attr(
            'src',
            configuration.imgPath + t
        );

        // Every 1000ms == animationTime, reDraw() the image , meaning get the cached image source and set it to the visible image
        setTimeout(updateBackground, configuration.animationTime);
    };

    /**
     * Updates the video background and shows/hides the red/grey badge according to open / closed online Filiale
     */
    var updateVideoComponent = function () {
        // if whisbiModeValue is not one of the valid (retail-store, call-center) will default to retail-store
        var whisbiModeValue = getUrlParameterValue('whisbimode') || '';

        // check holiday
        var isClosed = false;

        $.ajax({
            url: 'https://eweb6.vfd2-testnet.de/api/vfd2dyn/json/holidayservice/holidayservice.php?simulatedate=' + getUrlParameterValue('simulatedate'),
            method: 'post',
            dataType: 'json'
        }).done(function (response) {
            if (response) {
                if ('yes' === response.public_holiday) {
                    isClosed = true;
                }
            }

            if (isFilialeOpen(whisbiModeValue) && !isFilialeClosed() && !isClosed) {
                try {
                    updateBackground();

                    // Hide the red badge with the closing hours since Filiale is open
                    hideRedBadge();

                } catch (e) {}
            } else {
                // Update the text with the correct opening hours
                setRedBadgeText();
                // Hide the grey badge and show the red badge with closing hours message
                hideGreyBadge();
            }
        });
    };

    // Update the video component
    updateVideoComponent();

})();
